import * as React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ title ="Max your Sax! | Gradivari", description = "We make creative tools that make creative musicians" }) => {
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta charSet='utf-8' />
        <meta name='description' content={description} />
        <meta httpEquiv='Content-Type' content='text/html; charset=utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <meta name='keywords' content={'keywords'} />
        <meta
          prefix='og: http://ogp.me/ns#'
          property='og:image'
          content={'image'}
        />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:site' content='@agradivari' />
        <meta name='twitter:creator' content='@gradivari' />
        <meta name='twitter:title' content={title} />
        <meta name='twitter:description' content={description} />
        <meta property='twitter:image' content={'image'} />
        <meta
          prefix='og: http://ogp.me/ns#'
          property='og:type'
          content={'type'}
        />
        <meta property='og:site_name' content='Gradivari' />
        <meta property='og:url' content='https://gradivari.com' />
        <meta
          prefix='og: http://ogp.me/ns#'
          property='og:title'
          content={title}
        />
        <meta
          prefix='og: http://ogp.me/ns#'
          property='og:description'
          content={description}
        />
        <meta name='author' content='Gradivari' />
        <meta name='copyright' content='Gradivari' />
        <meta
          property='article:author'
          content='https://twitter.com/gradivari'
        />
        <meta
          property='article:publisher'
          content='https://twitter.com/gradivari'
        />
        <script type='application/ld+json'>
          {`
        {
          "@context": "https://schema.org",
          "@type": "Product",
          "email": "contact@gradivari.com",
          "name": "Gradivari",
          "url": "https://www.gradivari.com",
          "sameAs" : [
          "https://twitter.com/gradivari",
          "https://facebook.com/gradivari",
          ]
        }
      `}
        </script>
      </Helmet>
    </>
  );
};

export default Seo;
