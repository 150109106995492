import * as React from 'react';
import { Link } from 'gatsby';

import HorizontalSectionLine from '../inc/horizontalSectionLine';
import Layout from '../layout';

import SectionBg from '../../images/__new/mainpage/img4.jpg';

import BgImageOvalBorder from '../../images/oval_border.svg';

import FacebookIcon from '../../images/facebook.png';
import TwitterIcon from '../../images/twitter.png';
import InstagramIcon from '../../images/instagram.png';
import FooterLogo from '../../images/gradivari-logo.png';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='bgRectangle'>
        <img
          src={SectionBg}
          alt='Section bacground'
          className='sectionBg'
          loading='lazy'
        />
      </div>
      <Layout>
        <div className='bgImage'>
          <img
            className='pulseBase image1'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>

        <div className='riseAboveText'>
          <p>Rise above</p>
        </div>
        <div className='leftRightSection'>
          <div className='leftSection'>
            <div className='sectionTitle'>
              <h2 className='sectionTitleText'>
                We make creative tools that make creative musicians.
              </h2>
            </div>
            <HorizontalSectionLine />
            <div className='sectionDescription'>
              <p className='sectionDescriptionText'>
                When musicians play with a flow and freedom,
                <br /> they take their talent to the next level. We want to
                empower musicians all over the world to unleash <br /> their
                full potential and give them the tools that helps them become
                creative and free-spirited&nbsp;artists.
              </p>
            </div>
          </div>
          <div className='rightSection'>
            <div className='footerNav'>
              <div className='footerNavItem'>
                <Link to='/' className='footerNavText'>
                  HOME
                </Link>
              </div>
              <div className='footerNavItem'>
                <Link to='/vision' className='footerNavText'>
                  VISION
                </Link>
              </div>
              <div className='footerNavItem'>
                <Link to='/backstory' className='footerNavText'>
                  BACKSTORY
                </Link>
              </div>
              <div className='footerNavItem'>
                <Link to='/values' className='footerNavText'>
                  VALUES
                </Link>
              </div>
              <div className='footerNavItem'>
                <Link to='/storyworld' className='footerNavText'>
                  STORYWORLD
                </Link>
              </div>
              {/* <div className='footerNavItem'>
                <Link to='/our-story' className='footerNavText'>
                  CONTACT
                </Link>
              </div> */}
              <div className='footerNavItem'>
                <a
                  href='https://store.gradivari.com/'
                  className='footerNavText'
                >
                  SHOP
                </a>
              </div>
            </div>
            <div className='rightRightSection'>
              <div className='socialMedia'>
                <p className='socialText'>Follow us:</p>
                <div className='socialIconContainer'>
                  <div className='socialIcon'>
                    <img src={FacebookIcon} alt='Facebook Icon' />
                  </div>
                  <div className='socialIcon'>
                    <img src={TwitterIcon} alt='Facebook Icon' />
                  </div>
                  <div className='socialIcon'>
                    <img src={InstagramIcon} alt='Facebook Icon' />
                  </div>
                </div>
              </div>
              <div className='footerLogo'>
                <div className='footerLogoContainer'>
                  <img src={FooterLogo} alt='Gradivari Logo' loading='lazy' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footerHorizontalLine' />
        <div className='underFooterContainer'>
          <p className='underFooterText'>® Gradivari 2021</p>
        </div>
      </Layout>
    </footer>
  );
};

export default Footer;
