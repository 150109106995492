import React, { useState, useEffect } from 'react';
import Layout from '../layout';
import { Link } from 'gatsby';

import Logo from '../../../src/images/gradivari-logo.png';

const Header = ({ children, homepage, pageNotFound, backstory }) => {
  const [active, setIsActive] = useState(false);

  useEffect(() => {
    document.body.style.overflow = active ? 'hidden' : 'unset';
  });

  return (
    <header
      className={`${
        homepage
          ? 'header homepage'
          : pageNotFound
          ? 'header pageNotFound'
          : 'header'
      } ${backstory ? 'backstory' : ''}`}
    >
      <Layout>
        <div className={`${active ? 'active' : ''} mobileOverlay`}>
          <div className={'mobileNavList'}>
            <Link to='/' activeClassName='active'>
              HOME
            </Link>
            <Link to='/vision' activeClassName='active'>
              VISION
            </Link>
            <Link to='/backstory' activeClassName='active'>
              BACKSTORY
            </Link>
            <Link to='/values' activeClassName='active'>
              VALUES
            </Link>
            <Link to='/storyworld' activeClassName='active'>
              STORYWORLD
            </Link>
            {/* <Link to='/contact' activeClassName='active'>
              CONTACT
            </Link> */}
            <a href='https://store.gradivari.com/'>SHOP</a>
          </div>
        </div>
        <div className={'navBar'}>
          <Link to='/' className={'logo'}>
            <div className={'logoContainer'}>
              <img src={Logo} alt={'Gradivari logo'} />
            </div>
          </Link>
          <nav className={`navigation`}>
            <div
              className={`${active ? 'active' : ''} hamburger`}
              onClick={() => setIsActive(!active)}
              onKeyDown={(e) => e.key === 'Enter' && setIsActive(!active)}
              tabIndex={0}
              role='button'
            >
              <span className='line'></span>
              <span className='line'></span>
              <span className='line'></span>
            </div>
            <ul className={'navList'}>
              <li className={'navListItem'}>
                <Link to='/' activeClassName='active'>
                  HOME
                </Link>
              </li>
              <li className={'navListItem'}>
                <Link to='/vision' activeClassName='active'>
                  VISION
                </Link>
              </li>
              <li className={'navListItem'}>
                <Link to='/backstory' activeClassName='active'>
                  BACKSTORY
                </Link>
              </li>
              <li className={'navListItem'}>
                <Link to='/values' activeClassName='active'>
                  VALUES
                </Link>
              </li>
              <li className={'navListItem'}>
                <Link to='/storyworld' activeClassName='active'>
                  STORYWORLD
                </Link>
              </li>
              {/* <li className={'navListItem'}>
                <Link to='/contact' activeClassName='active'>
                  CONTACT
                </Link>
              </li> */}
              <li className={'navListItem'}>
                <a href='https://store.gradivari.com/'>SHOP</a>
              </li>
            </ul>
          </nav>
        </div>
        {children}
      </Layout>
    </header>
  );
};

export default Header;
